import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../Styles/_deposit.scss";
import { bankDetail } from "../../Utils/data";

const Deposit = () => {
  const dispatch = useDispatch();
  const { SportsbookGlobalVariable } = useSelector((state) => state.sportsBook);
  const [loading, setLoading] = useState();
  const [inputObject, setObject] = useState({
    name: "",
    phone: "",
    password: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    setObject({
      ...inputObject,
      [e.target.name]: e.target.value,
    });
  };

  const view = () => {};

  return (
    <div className="deposit">
      <div className="deposit-table">
        <div className="deposit-table-head">
          <div className="first">
            <h3 className="table-head">Payment Method</h3>
          </div>
          <div className="second">
            <h4 className="table-head">Paystack</h4>
          </div>
          <div className="third">
            <h4 className="table-head">F***</h4>
          </div>
          <div className="fourth">
            <h4 className="table-head">Minimum Amount</h4>
          </div>
          <div className="fifth" onClick={() => view()}>
            <h4 className="table-head"></h4>
          </div>
        </div>
        {bankDetail?.map((item, index) => (
          <div className="deposit-table-body" key={index}>
            <div className="first">
              <img src={item?.logo} alt="logo" />
            </div>
            <div className="second">
              <h4 className="">{item?.detail}</h4>
            </div>
            <div className="third">
              <h4 className="">None</h4>
            </div>
            <div className="fourth">
              <h4 className="">1000</h4>
            </div>
            <div className="fifth">
              <button className="btn">
                <a href={`/Account/BankDeposit/${item?.id}`}>+ Deposit</a>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Deposit;
