import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatNumber } from "../../Utils/helpers";
import { SHOW_MODAL } from "../../Redux/types";
import { ChangePasswordModal } from "../../Components/Modal/ChangePasswordModal";
import { getUsers } from "../../Redux/actions/user";
import useSWR from "swr";

export default function AgencyList() {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { shopUsers } = useSelector((state) => state.auth);
  const [filtered, setFiltered] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedId, setSelected] = useState("");

  const { data, error } = useSWR("/user/account/retail/users");
  const { SportsbookGlobalVariable } = useSelector((state) => state.sportsBook);

  useEffect(() => {
    document.body.classList = "bodyMain en-GB sport_master user Logged";

    if (data) {
      setUsers(data?.data);
      setFiltered(data?.data);
    }
  }, [data]);

  const showDownliners = (e, id) => {
    e.target.classList.toggle("sel");
    if (selectedId === id) {
      setSelected("");
    } else {
      setSelected(id);
      dispatch(getUsers(id));
    }
  };

  return (
    <div id="MainContent" className="">
      <div className="Riquadro">
        <div className="TopSX">
          <div className="TopDX ">
            <h3>Agency List</h3>
          </div>
        </div>
        <div className="CntSX">
          <div className="CntDX">
            <div className="betslist">
              <div className="RiquadroSrc">
                <div className="Cnt">
                  <div>
                    <div className="pb15 pt15" style={{ margin: "2rem 0" }}>
                      <table className="SearchContainerStyle">
                        <tbody>
                          <tr className="SearchSectionStyle">
                            <td className="SearchDescStyle">
                              Filter By
                              <select
                                name="ac$w$PC$PC$BetList$ddlFiltoData"
                                className="dropdownFiltoData"
                                style={{
                                  width: "150px",
                                  padding: ".5rem .1rem",
                                }}
                                onChange={(e) => {
                                  const val = e.target.value;
                                  if (val === "") {
                                    setFiltered(users);
                                  } else {
                                    setFiltered(
                                      users.filter(
                                        (user) =>
                                          user.rolename === e.target.value
                                      )
                                    );
                                  }
                                }}
                              >
                                <option value="">All</option>
                                {(user.role === "Super Agent" ||
                                  user.role === "Master Agent") && (
                                  <option value="Agent">Agent</option>
                                )}
                                {(user.role === "Super Agent" ||
                                  user.role === "Master Agent" ||
                                  user.role === "Agent") && (
                                  <option value="Shop">Shop</option>
                                )}
                                <option value="Cashier">Cashier</option>
                                <option value="Player">Player</option>
                              </select>
                            </td>
                            <td className="SearchControlsStyle">
                              <td className="SearchDescStyle">
                                Fast Search
                                <input
                                  name="ac$w$PC$PC$BetList$txtCodiceCoupon"
                                  type="text"
                                  defaultValue=""
                                  className="textbox dropdownFiltoData"
                                  style={{
                                    width: "150px",
                                    padding: ".5rem .1rem",
                                  }}
                                  onKeyUp={(e) => {
                                    const q = e.target.value;

                                    if (q.length >= 3) {
                                      setFiltered(
                                        users.filter((user) =>
                                          user.username.includes(q)
                                        )
                                      );
                                    }
                                    if (q === "") setFiltered(users);
                                  }}
                                />
                              </td>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="divDg">
                      <div className="">
                        <table
                          className="dgStyle"
                          cellSpacing="0"
                          border="0"
                          style={{
                            borderWidth: "0px",
                            borderStyle: "None",
                            width: "100%",
                            borderCollapse: "collapse",
                          }}
                        >
                          <tbody>
                            <tr className="dgHdrStyle">
                              <th width="10%"></th>
                              <th>User Type</th>
                              <th>Username</th>
                              <th>Name</th>
                              <th>Balance</th>
                              <th />
                            </tr>
                          </tbody>
                          <tbody>
                            {filtered &&
                              filtered.map((row, i) => (
                                <>
                                  <tr className="dgItemStyle txt-c" key={i}>
                                    <td>
                                      <span
                                        className="expand-user"
                                        onClick={(e) =>
                                          showDownliners(e, row.id)
                                        }
                                      ></span>
                                    </td>
                                    <td>{row.rolename}</td>
                                    <td>{row.username}</td>
                                    <td>{row.name}</td>
                                    <td>
                                      {SportsbookGlobalVariable.Currency}
                                      {formatNumber(row.available_balance)}
                                    </td>
                                    <td>
                                      <a
                                        href="javascript:;"
                                        onClick={() =>
                                          dispatch({
                                            type: SHOW_MODAL,
                                            payload: {
                                              open: true,
                                              title: "Change Password",
                                              component: (
                                                <ChangePasswordModal
                                                  user_id={row.id}
                                                  dispatch={dispatch}
                                                />
                                              ),
                                            },
                                          })
                                        }
                                      >
                                        <img src="/img/password.png" alt="" />
                                      </a>
                                    </td>
                                  </tr>
                                  {selectedId === row.id && (
                                    <tr key={`selected-${row.id}`}>
                                      <td></td>
                                      <td colSpan="6">
                                        <div
                                          style={{
                                            borderWidth: "0px",
                                            borderStyle: "None",
                                            width: "100%",
                                            textAlign: "left",
                                            borderCollapse: "collapse",
                                          }}
                                        >
                                          <div>
                                            {shopUsers?.map((user) => (
                                              <div
                                                key={user.id}
                                                style={{
                                                  textAlign: "center",
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                              >
                                                <p
                                                  style={{
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  {user.rolename}
                                                </p>
                                                <p
                                                  style={{
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  {user.username}
                                                </p>
                                                <p
                                                  style={
                                                    {
                                                      // marginLeft: "-1rem",
                                                    }
                                                  }
                                                >
                                                  {user.name}
                                                </p>
                                                <p>
                                                  {
                                                    SportsbookGlobalVariable.Currency
                                                  }
                                                  {formatNumber(
                                                    row.available_balance
                                                  )}
                                                </p>
                                                <p
                                                  style={{
                                                    marginRight: "1.7rem",
                                                  }}
                                                >
                                                  <a href="javascript:;">
                                                    <img
                                                      src="/img/password.png"
                                                      alt=""
                                                    />
                                                  </a>
                                                </p>
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </>
                              ))}
                            {filtered && filtered.length === 0 && (
                              <tr className="dgItemStyle">
                                <td colSpan="8" className="txt-c">
                                  No result found
                                </td>
                              </tr>
                            )}
                            {!filtered.length && (
                              <tr className="dgItemStyle">
                                <td colSpan="8" className="txt-c">
                                  Loading...
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
