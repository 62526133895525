import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generate } from "../../Services/apis";
import Modal from "../../Components/Modal";
import { Spinner } from "reactstrap";
/**
 * packages
 */

export const LastApproved = () => {
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [inputObject, setObject] = useState({
    totalCommissions: 0,
    totalSales: 0,
    totalTickets: 0,
    totalVbetTickets: 0,
    totalVbetSales: 0,
    totalVbetWinnings: 0,
    totalWinnings: 0,
    balance: 0,
    username: "",
    date: "",
  });

  const toggle = () => {
    setModal(!modal);
  };

  const handleChange = (e) => {
    e.preventDefault();
    setObject({
      ...inputObject,
      [e.target.name]: e.target.value,
    });
  };

  const create = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      agent_id: user.id,
      from: inputObject.from,
      to: inputObject.to,
    };
    await generate(payload)
      .then((r) => {
        setLoading(false);
        setDetails(r);
      })
      .catch((err) => {
        setLoading(false);
      });
    toggle();
  };

  useEffect(() => {
    setObject({
      totalCommissions: details?.totalCommissions,
      totalSales: details?.totalSales,
      totalTickets: details?.totalTickets,
      totalVbetTickets: details?.totalVbetTickets,
      totalVbetSales: details?.totalVbetSales,
      totalVbetWinnings: details?.totalVbetWinnings,
      totalWinnings: details?.totalWinnings,
      balance: details?.user?.balance,
      username: details?.user?.username,
      date: details?.created_at,
    });
  }, [details]);

  return (
    <>
      <div className="homeShop logged">
        <form>
          <div className="report">
            <div className="report-field">
              <label>From</label>
              <input type="date" onChange={handleChange} name="from" />
            </div>
            <div className="report-field">
              <label>To</label>
              <input type="date" onChange={handleChange} name="to" />
            </div>
          </div>
          <div className="btn-create">
            <button onClick={create}>
              {loading ? <Spinner /> : "Generate"}
            </button>
          </div>
        </form>
      </div>
      <Modal title={"Generate Reports"} modal={modal} toggle={toggle}>
        <form className="new-entry">
          <div className="entry-field">
            <label>Username</label>
            <input
              type="text"
              name="username"
              value={inputObject.username}
              disabled={true}
            />
          </div>
          <div className="entry-field">
            <label>Opening Balance</label>
            <input
              type="number"
              onChange={handleChange}
              name="balance"
              value={inputObject?.balance}
              disabled={true}
            />
          </div>
          {/* <div className="entry-field">
            <label>Date</label>
            <input
              type="date"
              name="date"
              value={inputObject?.date}
              disabled={true}
            />
          </div> */}
          <div className="entry-field">
            <label>Total Sales</label>
            <input
              type="number"
              onChange={handleChange}
              value={inputObject?.totalSales}
              name="totalSales"
              disabled={true}
            />
          </div>
          <div className="entry-field">
            <label>Total Tickets</label>
            <input
              type="number"
              onChange={handleChange}
              name="totalTickets"
              value={inputObject?.totalTickets}
              disabled={true}
            />
          </div>
          <div className="entry-field">
            <label>Total Vbet Sales</label>
            <input
              type="number"
              value={inputObject?.totalVbetSales}
              name="totalVbetSales"
              disabled={true}
            />
          </div>
          <div className="entry-field">
            <label>Total Vbet Tickets</label>
            <input
              type="number"
              onChange={handleChange}
              value={inputObject?.totalVbetTickets}
              name="totalVbetTickets"
              disabled={true}
            />
          </div>
          <div className="entry-field">
            <label>Total Winnings</label>
            <input
              type="number"
              onChange={handleChange}
              value={inputObject?.totalWinnings}
              name="totalWinnings"
              disabled={true}
            />
          </div>
          <div className="entry-field">
            <label>Total Vbet Winnings</label>
            <input
              type="number"
              onChange={handleChange}
              name="totalVbetWinnings"
              value={inputObject?.totalVbetWinnings}
              disabled={true}
            />
          </div>
          <div className="entry-field">
            <label>Total Commissions</label>
            <input
              type="number"
              onChange={handleChange}
              value={inputObject?.totalCommissions}
              name="totalCommissions"
              disabled={true}
            />
          </div>
          <div className="btn-create">
            <button onClick={toggle}>Close</button>
          </div>
        </form>
      </Modal>
    </>
  );
};
