import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PaystackButton } from "react-paystack";
import { useRavePayment } from "react-ravepayment";
import { toast } from "react-toastify";
import { bank, saveTransaction } from "../../Services/apis";
import "../../Styles/_deposit.scss";
import { bankDetail } from "../../Utils/data";
import { useParams } from "react-router-dom";
import { UPDATE_USER_BALANCE } from "../../Redux/types";
import { formatNumber } from "../../Utils/helpers";

const BankDeposit = ({ history }) => {
  const id = useParams();
  const { user } = useSelector((state) => state.auth);
  const [config, setConfig] = useState({
    txref: new Date().getTime(),
    customer_email: "sigbenuoghenero@gmail.com",
    customer_phone: "",
    customer_name: "",
    amount: "",
    PBFPubKey: "",
    apiKey: "MK_TEST_SAF7HR5F3F",
    contractCode: "4934121693",
    production: process.env.NODE_ENV === "production",
  });
  const [paymentSuccess, setPaymentSuccess] = useState("");
  const dispatch = useDispatch();
  const [details, setDetails] = useState([]);
  const [inputObject, setObject] = useState({
    amount: 0,
  });
  const { initializePayment } = useRavePayment(config);

  const onSuccess = (response) => {
    console.log(response);
    response.paymentMethod = details[0]?.slug;
    response.channel = "website";
    response.amount = config.amount;

    switch (details[0]?.slug) {
      case "rave":
        // console.log(response);
        if (response.respcode === "00") {
          response.reference = response.tx.txRef;
          setPaymentSuccess(
            `Success!! Your account has been credited with ${formatNumber(
              config.amount
            )}`
          );
          // update user balance
          dispatch({
            type: UPDATE_USER_BALANCE,
            payload: user.balance + config.amount,
          });

          saveTransaction(response);
        }
        break;
      case "paystack":
        if (response.message === "Approved") {
          setPaymentSuccess(
            `Success!! Your account has been credited with ${formatNumber(
              config.amount
            )}`
          );
          // update user balance
          dispatch({
            type: UPDATE_USER_BALANCE,
            payload: user.balance + config.amount,
          });

          saveTransaction(response);
        } else {
        }
        break;
      case "monnify":
        if (response.status === "SUCCESS") {
          setPaymentSuccess(
            `Success!! Your account has been credited with ${formatNumber(
              config.amount
            )}`
          );
          // update user balance
          dispatch({
            type: UPDATE_USER_BALANCE,
            payload: user.balance + config.amount,
          });
          response.reference = response.transactionReference;

          saveTransaction(response);
        }
        break;
    }
    setConfig({ ...config, amount: "" });
  };

  const onClose = () => {
    // console.log('closed');
  };

  const handleChange = (e) => {
    e.preventDefault();
    setObject({
      ...inputObject,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setDetails(getItem);
  }, []);

  const getItem = bankDetail.filter((item) => {
    return item.id === id.id;
  });

  const setAmount = (value) => {
    let currentAmount = inputObject?.amount;
    if (currentAmount === "") {
      currentAmount = 0;
    }
    setObject(currentAmount + value);
  };

  const verifyPayment = (response) => {
    if (config.amount > 0) {
      if (response.message === "Approved") {
        setPaymentSuccess(
          `Success!! Your account has been credited with ${formatNumber(
            config.amount
          )}`
        );
        // update user balance
        dispatch({
          type: UPDATE_USER_BALANCE,
          payload: user.balance + config.amount,
        });

        response.paymentMethod = "paystack";
        response.channel = "mobile";
        response.amount = config.amount;
        setConfig({ ...config, amount: "" });
        saveTransaction(response);
        // dispatch({type: SHOW_MODAL, payload: {show: true, type: 'error', message: 'Your'}})
      } else {
        // dispatch({type: SHOW_MODAL, payload: {show: true, type: 'error', message: 'We were unable to process your request'}})
      }
    }
  };

  function payWithMonnify() {
    window.MonnifySDK.initialize({
      amount: inputObject.amount,
      currency: "NGN",
      reference: "" + Math.floor(Math.random() * 1000000000 + 1),
      customerEmail: "sigbenuoghenero@gmail.com",
      apiKey: config.apiKey,
      contractCode: config.contractCode,
      paymentDescription: "Gaming Account funding",
      isTestMode: config.production,
      paymentMethods: ["CARD", "ACCOUNT_TRANSFER"],
      onComplete: function (response) {
        //Implement what happens when transaction is completed.
        onSuccess(response);
      },
      onClose: function (data) {
        //Implement what should happen when the modal is closed here
        console.log(data);
      },
    });
  }
  const onComplete = (response) => {
    console.log(response); // card charged successfully, get reference here
  };

  const close = (response) => {
    console.log(response);
  };

  return (
    <div className="deposit">
      <div className="deposit-step">
        <div className="left">
          <h3>Paystack</h3>
          <p>Find below-outlined steps on how to fund your wallet.</p>
          <ul>
            <li>1. Select Paystack as your means of Deposit</li>
            <li>1. Select Paystack as your means of Deposit</li>
            <li>1. Select Paystack as your means of Deposit</li>
            <li>1. Select Paystack as your means of Deposit</li>
          </ul>
        </div>
        <div className="right">
          <div className="top">
            <div className="right-head">
              <img src={details[0]?.logo} alt="logo" className="" />
            </div>
            <h2>{details[0]?.name}</h2>
          </div>
          <p className="pl-1">(Card/bank) Instant Credit</p>
          <div className="flex by-1">
            <p>Email: </p>
            <h5> hhhh@gmail.com</h5>
          </div>
          <div>
            <div className="flex">
              <label className="w-2"></label>
              <ul className="flex-list">
                <li onClick={() => setAmount(1000)}>
                  <span>N</span> <br />
                  +1000
                </li>
                <li onClick={() => setAmount(5000)}>
                  <span>N</span> <br />
                  +5,000
                </li>
                <li onClick={() => setAmount(10000)}>
                  <span>N</span> <br />
                  +10,000
                </li>{" "}
                <li onClick={() => setAmount(25000)}>
                  <span>N</span> <br />
                  +25,000
                </li>{" "}
                <li onClick={() => setAmount(50000)}>
                  <span>N</span> <br />
                  50,000
                </li>
              </ul>
            </div>
            <div className="flex my-1">
              <label className="w-2">Amount</label>
              <input
                name="amount"
                type="number"
                autoComplete="off"
                step="100"
                maxLength="5"
                min="100"
                max="10000"
                className="deposit-input"
                onChange={handleChange}
                value={inputObject?.amount}
              />
            </div>
            <div className="flex my-1">
              <label className="w-2">Choose type</label>
              <select name="amount" type="text">
                <option value="Card">Card</option>
                <option value="Card">USSD</option>
              </select>
            </div>
          </div>
          <div className="flex-list jb by-1">
            <div>
              <p>Fees:None </p>
            </div>
            <div>
              <p>Minimum Deposit:N100 </p>
            </div>
          </div>
          <div className="flex-list pl-1">
            <p>Currency: NGN </p>
          </div>
          <div className="flex-list jb by-1">
            <div>
              <button className="btn start">+ Deposit</button>
              {
                // {
                details[0]?.slug === "rave" && (
                  <button
                    className="btn green mt20 mb20"
                    onClick={() => initializePayment(onSuccess, onClose)}
                  >
                    {" "}
                    Make Payment
                  </button>
                )
              }
              {details[0]?.slug === "monnify" && (
                <>
                  <button
                    className="btn green mt20 mb20"
                    onClick={payWithMonnify}
                  >
                    {" "}
                    Make Payment
                  </button>
                </>
              )}
              {details[0]?.slug === "paystack" && (
                <PaystackButton
                  amount={config.amount * 100}
                  email={user?.email}
                  publicKey={config.PBFPubKey}
                  onSuccess={verifyPayment}
                  text="Make Payment"
                  disabled={parseInt(config.amount) === 0}
                  className="btn green mt20 mb20"
                />
              )}
            </div>
            <div>
              <button className="btn red" onClick={() => history.goBack()}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankDeposit;
