import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import moment from "moment";
import { getLoginHistory } from "../../Services/apis";
import Pagination from "../../Components/Pagination";
import { formatDate } from "../../Utils/helpers";

export const LoginHistory = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState({
    from: moment().subtract(1, "w").toDate(),
    to: moment().toDate(),
    page_size: 15,
  });

  const [pagination, setPagination] = useState({
    total: 0,
    per_page: 2,
    from: 1,
    to: 0,
    current_page: 1,
    last_page: 0,
  });

  useEffect(() => {
    document.body.classList = 'bodyMain en-GB sport_master accesslogs Logged';

    fetchResult(1);

  }, []);

  const handleChange = (name, value) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const fetchResult = (page) => {
    setLoading(true);
    const data = {
      from: moment(filterData.from).format("DD/MM/YYYY"),
      to: moment(filterData.to).format("DD/MM/YYYY"),
      page_size: filterData.page_size,
    };
    getLoginHistory(data, page)
      .then((res) => {
        setPagination({
          total: res.data.total,
          per_page: res.data.per_page,
          from: res.data.from,
          to: res.data.to,
          current_page: res.data.current_page,
          last_page: res.data.last_page,
        });
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div id="MainContent" className="">

    <div className="Riquadro">
      <div className="TopSX">
        <div className="TopDX">
          <h3>Access Logs</h3>
        </div>
      </div>
      <div className="CntSX">
        <div className="CntDX">
          <div id="s_w_PC_PC_panelSquare">
            <div className="RiquadroSrc">
              <div className="TopSX">
                <div className="TopDX"></div>
              </div>
              <div className="Cnt">
                <div>
                  <div className="SearchTitleStyle">
                    <table border="0" cellpadding="0" cellspacing="0" width="100%">
                      <tbody>
                        <tr>
                          <td>Filters</td>
                          <td className="SearchMinimizeStyle">
                            <a href="javascript:ShowHideSearch('tblSearch', 's_w_PC_PC_imgHide', 's_w_PC_PC_imgShow');">
                              <img
                                id="s_w_PC_PC_imgHide" src="../App_Themes/Bet9jaShop_1/Images/Icons/Minimizza_ico.png"
                                style={{borderWidth:'0px'}} />
                              <img id="s_w_PC_PC_imgShow"
                                src="../App_Themes/Bet9jaShop_1/Images/Icons/Ingrandire_ico.png"
                                style={{borderWidth: '0px', display:'none'}} />
                              </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <table id="tblSearch" className="SearchContainerStyle">
                    <tbody>
                      <tr className="SearchSectionStyle">
                        <td className="SearchDescStyle">
                          Date
                        </td>
                        <td className="SearchControlsStyle">
                          <table>
                            <tbody>
                              <tr>
                                <td width="20%" align="right">
                                  From
                                </td>
                                <td>
                                  <table cellpadding="0" cellspacing="0">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            selected={filterData.from}
                                            className="textbox"
                                            style={{width:'75px' }}
                                            onChange={date => handleChange('from', date)} 
                                          />
                                        </td>
                                        <td width="25px" align="center">
                                          <img
                                            src="/img/Calendar.gif"
                                            alt="Display Calendar"
                                            style={{ borderWidth: '0px', cursor: 'pointer' }} 
                                          />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td width="20%" align="right">
                                  To
                                </td>
                                <td width="30%" align="left">
                                  <table cellpadding="0" cellspacing="0">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            selected={filterData.to}
                                            className="textbox"
                                            style={{width:'75px' }}
                                            onChange={date => handleChange('to', date)} 
                                          />
                                        </td>
                                        <td width="25px" align="center">
                                          <img
                                            src="/img/Calendar.gif"
                                            alt="Display Calendar"
                                            style={{ borderWidth: '0px', cursor: 'pointer' }} 
                                          />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                    </tbody>
                  </table>

                  <table className="SearchButtonsStyle">
                    <tbody>
                      <tr>
                        <td></td>
                        <td className="tdSrcSX">
                          <input 
                            type="submit" 
                            name="s$w$PC$PC$btnCancella" 
                            value="Cancel"
                            className="button"
                          />
                        </td>
                        <td className="tdSrcDX">
                          <input 
                            type="submit"
                            name="s$w$PC$PC$btnAvanti" 
                            value="Continue"
                            className="button" 
                            onClick={() => fetchResult(1)}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                </div>

              </div>
            </div>
            <div className="BtmSX">
              <div className="BtmDX"></div>
            </div>
          </div>
          <div className="divDg">
            <div>
              <table className="dgStyle" cellspacing="0" border="0" id="s_w_PC_PC_grid"
                style={{ borderWidth: '0px', borderStyle: 'None', width: '100%', borderCollapse: 'collapse'}}>
                <tbody>
                  <tr className="dgHdrStyle">
                    <th align="center" scope="col"><a href="javascript:__doPostBack('s$w$PC$PC$grid','Sort$IDAccesso')">ID</a></th>
                    <th align="center" scope="col">Date</th>
                    <th align="center" scope="col"><a href="javascript:__doPostBack('s$w$PC$PC$grid','Sort$TipoAccesso')">Client Type</a>
                    </th>
                    <th align="center" scope="col">
                      <a href="javascript:__doPostBack('s$w$PC$PC$grid','Sort$IndirizzoIP')">IP Address</a>
                    </th>
                  </tr>
                  {loading ? (
                    <tr className="dgItemStyle">
                      <td colSpan="4">Loading...Please wait!</td>
                    </tr>
                  ) : data.length > 0 ? (
                    data.map((row, i) => (
                      <tr className="dgItemStyle" key={row.id}>
                        <td align="center">{row.id}</td>
                        <td align="center">
                          <span id="s_w_PC_PC_grid_ctl02_lblData">{formatDate(row.created_at, 'DD/MM/YYYY HH:mm:ss')}</span>
                        </td>
                        <td align="center">{row.client_type}</td>
                        <td align="center">{row.login_ip} </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="dgEmptyStyle">
                      <td colSpan="4">No record found</td>
                    </tr>
                  )}
                  <Pagination
                    colspan={9}
                    data={pagination}
                    offset={10}
                    changePage={fetchResult}
                  />
                </tbody>
              </table>
            </div>
          </div>


        </div>
      </div>
    </div>
    <div className="BtmSX">
      <div className="BtmDX"></div>
    </div>
  </div>
  );
};
