import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { LOADING, SHOW_MODAL } from "../../Redux/types";
import { payoutTicket } from "../../Services/apis";
import { formatNumber } from "../../Utils/helpers";

function PayoutTicket({ betslip, refreshList }) {
  const dispatch = useDispatch();
  const {loading} = useSelector((state) => state.login);

  const submit = () => {
    dispatch({ type: LOADING });
    payoutTicket(betslip?.id).then((res) => {
      dispatch({ type: LOADING });
      if (res.success) {
        toast.success("PAID!!");
        refreshList();
        dispatch({type: SHOW_MODAL, payload: null});
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <div
      style={{ textAlign: "center", marginBottom: "4rem", marginTop: "2rem" }}
    >
      <h4 style={{ color: "#4c4c4c" }}>
      Confirm Payout on <span className="ng-binding">{betslip?.betslip_id}</span>
      </h4>
      <h2 style={{color: "green"}}>₦{formatNumber(betslip?.winnings)}</h2>
      <div style={{ display: "flex", justifyContent: "center", marginTop: '10px' }}>
        <button
          className="red"
          onClick={submit}
          style={{
            background: "red",
            color: "white",
            marginRight: "1rem",
            border: "0px",
            padding: ".3rem 1rem",
          }}
        >
          YES {loading && <i className="fa fa-spin fa-spinner" /> }
        </button>
        <button
          className="green"
          onClick={() => dispatch({type: SHOW_MODAL, payload: null})}
          style={{
            background: "#000229",
            color: "white",
            marginRight: "1rem",
            border: "0px",
            padding: ".3rem 1rem",
          }}
        >
          NO
        </button>
      </div>
    </div>
  );
}

export default PayoutTicket;
