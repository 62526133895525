import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { SHOW_MODAL } from "../Redux/types";
import ElbetFunds from "../pages/TransferElbet";
import { useDispatch } from "react-redux";

export const AccountMenu = ({ componentClase, user }) => {
  const [isCancel, setCancel] = useState({ show: false, ticketId: "" });
  const dispatch = useDispatch();
  const closeModal = () => {
    setCancel({ ...isCancel, show: false });
  };
  return (
    <div className={componentClase}>
      <ul>
        <li>
          <a title="My Account" href="#">
            My Account
          </a>
          <ul>
            <li>
              <NavLink title="Bet List" to="/Account/BetList">
                Bet List
              </NavLink>
            </li>
            <li>
              <NavLink title="Bet List" to="/Account/BetListPayout">
                Bet List Payout
              </NavLink>
            </li>
            <li>
              <NavLink title="Financial Report" to="/Account/FinancialReport">
                Financial Report
              </NavLink>
            </li>
            <li>
              <NavLink title="Coupon Bet List" to="/Account/CouponBetList">
                Coupon Bet List
              </NavLink>
            </li>
            <li>
              <NavLink title="Transactions List" to="/Account/TransactionList">
                Transactions List
              </NavLink>
            </li>
            <li>
              <NavLink
                title="User Transactions List"
                to="/Account/TransactionList"
              >
                User Transactions List
              </NavLink>
            </li>
            <li>
              <NavLink
                title="Bonus Transactions List"
                to="/Account/BonusTransactionList"
              >
                Bonus Transactions List
              </NavLink>
            </li>
            <li>
              <NavLink title="Cash In" to="/Account/Cash-Book">
                Cash Book
              </NavLink>
            </li>
            <li>
              <NavLink title="Deposit" to="/Account/Deposit">
                Deposit
              </NavLink>
            </li>
            <li>
              <NavLink title="Withdraw" to="/Account/Withdrawal">
                Withdraw
              </NavLink>
            </li>
          </ul>
        </li>
        <li>
          <a title="Change Password" href="#">
            PDR
          </a>
          <ul>
            {user?.role === "Shop" ? (
              <li className="uxr-li-link">
                <NavLink to="/Account/NewUser?usertype=cashier">
                  New Cashier
                </NavLink>
              </li>
            ) : (
              <li className="uxr-li-link">
                <NavLink to="/Account/NewUser?usertype=player">
                  New User
                </NavLink>
              </li>
            )}
            {(user?.role === "Master Agent" ||
              user?.role === "Super Agent" ||
              user?.role === "Agent") && (
              <li className="uxr-li-link">
                <NavLink to="/Account/NewUser?usertype=shop">New Shop</NavLink>
              </li>
            )}
            {(user?.role === "Master Agent" ||
              user?.role === "Super Agent") && (
              <li className="uxr-li-link">
                <NavLink to="/Account/NewUser?usertype=agent">
                  New Agent
                </NavLink>
              </li>
            )}
            {/* <li className="uxr-li-link">
              <a href="https://globalbet.virtual-horizon.com/engine/backoffice/login_BU.htm#accounts"
                  target="_blank" className="level-2">
                  Virtual Credit
              </a>
            </li> */}
            {(user?.role === "Master Agent" ||
              user?.role === "Super Agent" ||
              user?.role === "Agent" ||
              user?.role === "Shop") && (
              <li className="uxr-li-link">
                <NavLink to="/Account/AgencyList">Agency List</NavLink>
              </li>
            )}

            {user?.role === "Shop" ? (

                <li
                className="uxr-li-link"
                onClick={() =>
                  dispatch({
                    type: SHOW_MODAL,
                    payload: {
                      open: true,
                      title: "TRANSFER TO ELBET ACCOUNT",
                      component: <ElbetFunds toggle={closeModal} />,
                    },
                  })
                }
                >
                <NavLink to="#">Transfer Funds (Elbet)</NavLink>
                </li>
            
            ) : (
              ""
              // <li
              //   className="uxr-li-link"
              //   onClick={() =>
              //     dispatch({
              //       type: SHOW_MODAL,
              //       payload: {
              //         open: true,
              //         title: "TRANSFER TO ELBET ACCOUNT",
              //         component: <ElbetFunds toggle={closeModal} />,
              //       },
              //     })
              //   }
              // >
              //   <NavLink to="#">Transfer Funds (Elbet)</NavLink>
              // </li>
            )}
            <li className="uxr-li-link">
              <NavLink to="/Account/Transfer/internal">
                {
                  {
                    'Cashier': "Transfer Funds Online",
                    "Shop": "Transfer Funds to Cashier",
                    "Agent": "Transfer Funds to Shop",
                    "Master Agent": "Transfer Funds to Agent",
                    "Super Agent": "Transfer Funds to Mst. Agent"
                  }[user?.role]
                }
                
              </NavLink>
            </li>
          </ul>
        </li>
        <li>
          <a title="Account Detail" href="#">
            Account Detail
          </a>
          <ul>
            <li>
              <NavLink title="Change Password" to="/Account/ChangePassword">
                Change Password
              </NavLink>
            </li>
            <li>
              <NavLink title="Change Password" to="/Account/PersonalDetails">
                Personal Data
              </NavLink>
            </li>
            <li>
              <NavLink title="Access Logs" to="/Account/LoginHistory">
                View Session
              </NavLink>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};
