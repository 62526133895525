import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AccountMenu } from "../Components/AccountMenu";
import { MD5 } from "crypto-js";
import Modal from "../Components/Modal";
import Withdraw from "./Account/Withdraw"; 
import AddUser from "./Account/AddUser";
import { HomeComponent } from "../Components/HomeComponent";
import { CouponCheckHome } from "../Components/CouponCheckHome";
import { OnlineWithdrawal } from "../Components/OnlineWithdrawal";
import { SHOW_MODAL } from "../Redux/types";
import ElbetFunds from "./TransferElbet";

export const Home = ({ history }) => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const [hash, setHash] = useState("");
  const backurl = process.env.REACT_APP_URL;
  const privateKey = process.env.REACT_APP_XPRESS_PRIVATE_KEY;
  const [modal, setModal] = useState(false);
  const [, refresh] = useState();
  const [inputObject, setInput] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.classList.add("defaultlogged", "Logged");
    document.body.classList.remove("default", "Anonymous");
    if (!isAuthenticated) {
      history.push("/Login");
      document.body.classList.remove("defaultlogged", "Logged");
      document.body.classList.add("default", "Anonymous");
    } else {
      setHash(
        MD5(
          `${user.auth_code}10160${backurl}1${user.group}retail${privateKey}`
        ).toString()
      );
    }
  }, [isAuthenticated, user]);

  const toggle = () => {
    setModal(!modal);
    refresh();
  };

  const closeModal = () => {
    dispatch({
      type: SHOW_MODAL,
      payload: {
        open: false,
      },
    });
  };
  
  return (
    <>
      <AccountMenu componentClase="homeLoggedMenu" user={user} />
      <div className="homeShop logged">
        <div className="bottoni">
          {user?.role === 'Cashier' && <a className="bookBet" href="/Sport/Cashdesk"></a>}
          <a
            className="printSoccer"
            href={`${process.env.REACT_APP_BASEURL}/soccer-print`}
            target="_blank"
          ></a>
          {/* <a id="hl_w_PC_SoccerSpecials" className="printSoccerSpecials new" href="GroupsExt.aspx?IDSport=542" target="_blank"></a> */}
          <a
            className="printToday"
            href={`${process.env.REACT_APP_BASEURL}/today-print`}
            target="_blank"
          ></a>
           <a 
            className="printOdds" 
            href={`${process.env.REACT_APP_BASEURL}/combo-print`}
            target="_blank"
          ></a>
          <a
            className="liveviewer"
            href="https://live.sportsbookengine.com/ "
            target="_blank"
          ></a>
          <a className="live-scores" href="/Sport/Livescore"></a>
          <a className="transfer" href={`/Account/Transfer/${"internal"}`}></a>
          {/* <a className="casino" href="#" target="_blank"></a> */}
          {user?.role === "Shop" ? (
            <a
              className="addcash"
              onClick={() =>
                dispatch({
                  type: SHOW_MODAL,
                  payload: {
                    open: true,
                    title: "ADD CASHIER",
                    component: (
                      <AddUser toggle={closeModal} userType="cashier" />
                    ),
                  },
                })
              }
            ></a>
          ) : (
            <>
              <a
                className="zoroplay-v"
                href={`${process.env.REACT_APP_XPRESS_LAUNCH_URL}?token=${user.auth_code}&game=10160&backurl=${backurl}&mode=1&group=${user.group}&clientPlatform=retail&h=${hash}`}
                target="_blank"
              ></a>
              {/* <a
                onClick={() =>
                  dispatch({
                    type: SHOW_MODAL,
                    payload: {
                      open: true,
                      title: "TRANSFER TO ELBET ACCOUNT",
                      component: <ElbetFunds toggle={closeModal} />,
                    },
                  })
                }
                className="luckyballs"
              ></a> */}
            </>
          )}
          {/* <a className="live-casino" href="#" target="_blank"></a> */}

          {user?.role === "Cashier" && (
            <a
              className="adduser"
              onClick={() =>
                dispatch({
                  type: SHOW_MODAL,
                  payload: {
                    open: true,
                    title: "ADD USER",
                    component: (
                      <AddUser toggle={closeModal} userType="player" />
                    ),
                  },
                })
              }
            ></a>
          )}
          {/* <a type="button" className="SimulateGames newOrange" id="SimulateGames" onclick="toggleCouponSimulateVisualization();"></a> */}
          {/* <a className="online-deposit" href="#" target="_blank"></a> */}
        </div>
        <div className="boxInserimento">
          <div className="check">
            <CouponCheckHome dispatch={dispatch} />

            <OnlineWithdrawal dispatch={dispatch} />
          </div>
        </div>
        <HomeComponent user={user} />
      </div>
      <Modal
        title={"APPROVE WITHDRAWAL"}
        modal={modal}
        toggle={toggle}
        children={<Withdraw toggle={toggle} code={inputObject.withdraw_code} />}
      />
    </>
  );
};
