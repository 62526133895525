import { REMOVE_USER_DATA, UPDATE_USER_BALANCE } from "../Redux/types";
import { NavLink } from "react-router-dom";
import React, { useState } from "react";
import { formatNumber } from "../Utils/helpers";
import { useSelector } from "react-redux";
import { authDetails } from "../Services/apis";

export const AppHeader = ({ user, dispatch, isAuthenticated }) => {
  const {loading} = useSelector((state) => state.login);
  const {SportsbookGlobalVariable} = useSelector((state) => state.sportsBook);
  const [refreshing, setRefresh] = useState(false);

  const refreshBalance = () => {
    setRefresh(true);
    authDetails().then(resp => {
        setRefresh(false);
        if(resp.user) {
            const user = resp.user;
            dispatch({
                type: UPDATE_USER_BALANCE,
                payload: user.balance
            });
        }
    }).catch(err => {
        setRefresh(false);

    });
}

  // const {SportsbookGlobalVariable} = useSelector((state) => state.sportsBook);
  console.log(SportsbookGlobalVariable);

  return (
    <div id="divHeader">
      {loading &&
      <div className="MenuUpdate">
        <div
          role="status"
          aria-hidden="true"
        >
          Please wait&nbsp;
          <img
            src="/img/wait_top.gif"
            align="absmiddle"
            style={{ borderWidth: "0px" }}
            alt="waiting"
          />
        </div>
      </div>}
      <div className="content_Header">
        <div className="Logo">
          <a href="/" id="h_w_lnkHP">
            <img
              id="h_w_Image1"
              src="/img/logo.png"
              style={{ borderWidth: "0px", width: "130px" }}
              alt="logo"
            />
          </a>
        </div>
        <div className="Tabs">
          <ul>
            <li>
              <NavLink className="sel" title="Sport" to="/Sport/Cashdesk">
                Sport
              </NavLink>
            </li>
            <li>
              <a
                title="Live Viewer"
                href="https://live.sportsbookengine.com/"
                target="_blank"
              >
                Live Viewer TV
              </a>
            </li>
            <li>
              <a title="Soccer Print" href={`${process.env.REACT_APP_BASEURL}/soccer-print`} target="_blank">
                Soccer Print
              </a>
            </li>
            <li>
              <a
                title="Today's Print"
                href={`${process.env.REACT_APP_BASEURL}/today-print`}
                target="_blank"
              >
                Today's Print
              </a>
            </li>
            <li>
              <a title="Cashdesk" href="/Sport/Cashdesk">
                Cashdesk
              </a>
            </li>
            <li>
              <a title="Races" href="/Account/Cash-Book">
                CashBook
              </a>
            </li>
            <li>
              <a title="Races" href="#">
                Lucky Balls
              </a>
            </li>
          </ul>
        </div>
        <div className="GMTPanel">
          Timezone:
          <div className="GMT_content_wrapper True">
            <div className="GMTFlags ">
              <div className="GMT41 Sel">
                <a
                  href="../ChooseGMT.aspx?ID=41&amp;url=/Sport/Default.aspx"
                  className="lnkGMTBtn"
                  title="GMT+01:00"
                >
                  {process.env.REACT_APP_REGION}
                </a>
              </div>
            </div>
            <div id="btnGMTList">
              <a id="btnGMTList_link" href="#" />
            </div>
          </div>
        </div>
        <div className="Lang_content_wrapper">
          <div className="languageFlags">
            <div className="en-GB Sel">
              <a
                href="../ChooseLanguage.aspx?ID=2&amp;url=/Sport/Default.aspx"
                className="lnkLangBtn"
                title="English"
              >
                English
              </a>
            </div>
          </div>
          <div id="btnLangList">
            <a id="btnLangList_link" href="#" />
          </div>
        </div>

        {isAuthenticated && (
          <div className="AreaRiservata">
            <div className="divLoginLogged">
              <div></div>
              <table
                className="tblLoginLogged"
                cellPadding="0"
                cellSpacing="3"
                width="100%"
              >
                <tbody>
                  <tr>
                    <td className="tdUser">
                      <div id="hl_w_cLogin_UpPanelLogin">
                        <span className="IDUtente">{user?.code}</span>&nbsp;
                        {user?.username}&nbsp;&nbsp;
                      </div>
                    </td>
                    <td>
                      <NavLink
                        className="btnLogout"
                        to="/Login"
                        onClick={() => dispatch({ type: REMOVE_USER_DATA })}
                      >
                        Logout
                      </NavLink>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div id="divSaldo">
                <span>
                  <div className="TitoloValuta">
                    <span>Currency</span>:<span>SSP</span>
                  </div>
                  <div className="TitoloSaldo">
                    <span>Availability</span>:
                    <span>{formatNumber(user?.available_balance)}&nbsp;{SportsbookGlobalVariable.Currency}</span>
                    {!refreshing ? 
                      <input
                      type="image"
                      name="hl$w$cLogin$btnSaldo"
                      className="btnRefreshSaldo"
                      src="/img/Refresh_ico.png"
                      alt="Refresh balance"
                      align="absmiddle"
                      onClick={refreshBalance}
                      style={{ borderWidth: "0px" }}
                    /> : <i className={`fa fa-spin fa-spinner`} /> }
                    
                  </div>
                  <div className="divSaldoUtente">
                    <span>Balance</span>:
                    <span>{formatNumber(user?.balance)}&nbsp;{SportsbookGlobalVariable.Currency}</span>
                  </div>
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
