import React from "react";
import { formatDate, formatNumber } from "../Utils/helpers";
import useSWR from "swr";

export const HomeComponent = ({ user }) => {
  const { data: transactionsData, error } = useSWR(
    "user/account/last-10-transactions"
  );
  
  return (
    <div id="MainContent">
      <div className="RiquadroHome">
        <div className="Cnt">
          <div>
            <div className="RiquadroHomeUsr">
              <div className="Cnt">
                <div>
                  <table
                    width="100%"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <table
                            cellpadding="'0"
                            cellspacing="0"
                            width="100%"
                          >
                            <tbody>
                              <tr>
                                <td className="welcomeUser">
                                  <span id="hl_w_PC_PC_lblBentornato">
                                    Welcome
                                  </span>
                                  &nbsp;
                                  <b>
                                    <span
                                      id="hl_w_PC_PC_lblUtente"
                                      className="userLabel"
                                    >
                                      {user?.username}
                                    </span>
                                  </b>
                                </td>
                                <td className="pulsantiLogged">
                                  <span>
                                    <a
                                      id="A1"
                                      className="divAccessLogs"
                                      href="/Account/AccessLogs.aspx"
                                    >
                                      <img
                                        src="img/login.png"
                                        border="0"
                                        alt="Logins report"
                                      />
                                    </a>
                                  </span>
                                  <span id="divDatiUtente">
                                    <a id="A2" href="../Account/User.aspx">
                                      <img
                                        src="img/config.png"
                                        border="0"
                                        alt="User Details"
                                      />
                                    </a>
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div id="divAgente" className="divTblAgente">
                            <div id="hl_w_PC_PC_divRiepilogoAgente">
                              <div>
                                <table
                                  className="dgDefaultLoggedStyle"
                                  cellspacing="0"
                                  border="0"
                                  style={{ borderCollapse: "collapse" }}
                                >
                                  <tbody>
                                    <tr className="dgHdrDefaultLoggedStyle">
                                      <th scope="col">&nbsp;</th>
                                      <th align="center" scope="col">
                                        Availability
                                      </th>
                                      <th align="center" scope="col">
                                        Credit
                                      </th>
                                      <th align="center" scope="col">
                                        Balance
                                      </th>
                                    </tr>
                                    <tr className="dgFirstItemStyle">
                                      <td>Account</td>
                                      <td align="right">
                                        {formatNumber(user?.available_balance || 0)}
                                      </td>
                                      <td align="right">
                                        {formatNumber(
                                          user?.trust_balance || "0.00"
                                        )}
                                      </td>
                                      <td align="right">
                                        {formatNumber(user?.available_balance || 0)}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <table cellpadding="0" cellspacing="0" width="100%">
              <tbody>
                <tr>
                  <td>
                    <div className="CassaStats">
                      <div className="CassaStatsTitle">CASHIER</div>
                      <table cellpadding="0" cellspacing="0" width="100%">
                        <tbody>
                          <tr className="CassaStatsItem">
                            <td>SportsBetting</td>
                            <td align="right">
                              {formatNumber(user?.available_balance || 0)}
                            </td>
                          </tr>
                          <tr className="CassaStatsTotals">
                            <td>Total Balance</td>
                            <td align="right">
                              {formatNumber(user?.available_balance || 0)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <div className="LastTrans">
                      <div className="LastTransTitle">
                        <a
                          title="See all transactions"
                          href="../Account/TransactionList.aspx"
                        >
                          Last 10 Transactions
                        </a>
                      </div>
                      <div className="LastTransDivCnt">
                        <div>
                          <table
                            cellspacing="0"
                            cellpadding="0"
                            border="0"
                            style={{
                              borderWidth: "0px",
                              width: "100%",
                              borderCollapse: "collapse",
                            }}
                          >
                            <tbody>
                              {transactionsData?.data.map(
                                (transaction, t) => (
                                  <tr
                                    className={`${
                                      t % 2 === 0
                                        ? "LastTransAItem"
                                        : "LastTransItem"
                                    }`}
                                    key={transaction.id}
                                  >
                                    <td>
                                      <a href="#">
                                        {transaction.reference_no}
                                      </a>
                                    </td>
                                    <td className="TemplateFieldOneColumn">
                                      <span>
                                        {formatDate(
                                          transaction.created_at,
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </span>
                                    </td>
                                    <td>{transaction.subject}</td>
                                    <td align="right">
                                      {`${
                                        transaction.tranx_type === "debit"
                                          ? "-"
                                          : ""
                                      }` + formatNumber(transaction.amount)}
                                    </td>
                                    <td align="right">
                                      {transaction.tranx_type === "credit"
                                        ? formatNumber(
                                            transaction.to_user_balance
                                          )
                                        : formatNumber(
                                            transaction.from_user_balance
                                          )}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
