import React, { useState } from "react";
import { useEffect } from "react";
import { CashIn } from "../Account/CashIn";
import { CashOut } from "../Account/CashOut";
import { Expenses } from "../Account/Expenses";
import { LastApproved } from "../Account/LastApproved";
const CashBook = () => {
  const [activeTab, setActive] = useState(1);
  
  useEffect(() => {
    document.body.classList = 'bodyMain en-GB sport_master user Logged';

  }, []);
  return (
    <div id="MainContent" className="">
            <div className="Riquadro">
                <div className="CntSX">
                    <div className="CntDX">
                        <div id="s_w_PC_PC_panelSquare">
                            <div className="RiquadroNews Reg">
                                <div className="Cnt"></div>
                                <div className="cashbook">
                                  <ul className="t-header">
                                    <li
                                      onClick={() => setActive(1)}
                                      className={activeTab === 1 ? `active tab-item ml` : "tab-item ml"}
                                    >
                                      Cash Book
                                    </li>
                                    <li
                                      onClick={() => setActive(2)}
                                      className={activeTab === 2 ? `active tab-item` : "tab-item"}
                                    >
                                      Cash In
                                    </li>
                                    <li
                                      onClick={() => setActive(3)}
                                      className={activeTab === 3 ? `active tab-item` : "tab-item"}
                                    >
                                      Cash Out
                                    </li>
                                    <li
                                      onClick={() => setActive(4)}
                                      className={activeTab === 4 ? `active tab-item` : "tab-item"}
                                    >
                                      Expenses
                                    </li>
                                  </ul>
                                  <div className="tab-content">
                                    {activeTab === 1 ? <LastApproved /> : ""}
                                    {activeTab === 2 ? <CashIn /> : ""}
                                    {activeTab === 3 ? <CashOut /> : ""}
                                    {activeTab === 4 ? <Expenses /> : ""}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>

  );
};

export default CashBook;
