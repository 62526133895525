import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { LOADING, SHOW_MODAL } from "../../Redux/types";
import { cancelTicket } from "../../Services/apis";

function CancelTicket({ id, refreshList }) {
  const dispatch = useDispatch();
  const {loading} = useSelector((state) => state.login);

  const doCancel = () => {
    dispatch({ type: LOADING });
    cancelTicket(id).then((res) => {
      dispatch({ type: LOADING });
      if (res.success) {
        toast.success("Ticket has been canceled");
        refreshList()
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <div
      style={{ textAlign: "center", marginBottom: "4rem", marginTop: "2rem" }}
    >
      <h4 style={{ color: "#4c4c4c" }}>Are you sure you want to cancel this ticket ?</h4>
      <span style={{ color: "red" }}>
        Note: You can only cancel tickets within 5 mins after
        registering the ticket
      </span>
      <div style={{ display: "flex", justifyContent: "center", marginTop: '10px' }}>
        <button
          className="red"
          onClick={doCancel}
          style={{
            background: "red",
            color: "white",
            marginRight: "1rem",
            border: "0px",
            padding: ".3rem 1rem",
          }}
        >
          YES {loading && <i className="fa fa-spin fa-spinner" /> }
        </button>
        <button
          className="green"
          onClick={() => dispatch({type: SHOW_MODAL, payload: null})}
          style={{
            background: "#000229",
            color: "white",
            marginRight: "1rem",
            border: "0px",
            padding: ".3rem 1rem",
          }}
        >
          NO
        </button>
      </div>
    </div>
  );
}

export default CancelTicket;
