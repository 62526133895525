import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { shopAddUser } from "../../Services/apis";
import { SHOW_MODAL } from "../../Redux/types";
import { toast } from "react-toastify";
import { ErrorPopUp, SuccessPopUp } from "../../Utils/toastify";
import FundAccount from "./FundAccount";

const AddUser = ({ toggle, userType }) => {
  const dispatch = useDispatch();
  const { SportsbookGlobalVariable } = useSelector((state) => state.sportsBook);
  const [loading, setLoading] = useState();
  const [inputObject, setObject] = useState({
    name: "",
    phone: "",
    password: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    setObject({
      ...inputObject,
      [e.target.name]: e.target.value,
    });
  };

  const createUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    const name = inputObject.name.split(" "); //split name to get first and last name
    const payload = {
      first_name: name[0],
      last_name: name[1],
      password: inputObject.password,
      phone_number: inputObject.phone,
      username: inputObject.phone,
      user_type: userType,
      country: "254",
      state: "",
      language: "EN",
      currency: SportsbookGlobalVariable.Currency,
      date_of_birth: "",
      gender: "Male",
      address: "",
      email: "",
    };
    shopAddUser(payload)
      .then((r) => {
        if (r.success) {
          SuccessPopUp("Successfully Created User");
          dispatch({
            type: SHOW_MODAL,
            payload: {
              open: true,
              title: "FUND ACCOUNT",
              component: <FundAccount user={r.data.username} />,
            },
          });
          setLoading(false);
        } else {
          ErrorPopUp(r.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.status === 422) {
          let errors = Object.values(err?.response?.data?.errors);
          errors = errors?.flat();
          toast.error(errors[0]);
        }
        toast.error(err?.response?.data?.message);
      });
  };

  return (
    <form className="expense-form">
      <div className="expense-input">
        <label>NAME</label>
        <input type="text" onChange={handleChange} name="name" />
      </div>
      <div className="expense-input">
        <label>PASSWORD</label>
        <input type="pasword" onChange={handleChange} name="password" />
      </div>
      <div className="expense-input">
        <label>PHONE</label>
        <div style={{ display: "flex" }}>
          <select name="pre" id="" className="nxmob-select">
            <option value="+220">+223</option>
          </select>
          <input type="text" onChange={handleChange} name="phone" />
        </div>
      </div>
      <div className="expense-btn">
        <input
          type="button"
          name="expenses"
          value={loading ? "LOADING" : "CREATE"}
          className="btn-green"
          onClick={(e) => createUser(e)}
        />
        <input
          type="button"
          name="expenses"
          value="CANCEL"
          className=" btn-red"
          onClick={() =>
            dispatch({
              type: SHOW_MODAL,
              payload: {
                open: false,
              },
            })
          }
        />
      </div>
    </form>
  );
};

export default AddUser;
