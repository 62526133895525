import React, { useEffect } from "react";
/**
 * packages
 */
import { Switch, Route, withRouter } from "react-router-dom";
import { TransactionList } from "../pages/Account/TransactionList";
import PersonalData from "../pages/Account/PersonalData";
import ChangePassword from "../pages/Account/ChangePassword";
import AgencyList from "../pages/Account/AgencyList";
import { BetList } from "../pages/Account/BetList";
import { BetListPayout } from "../pages/Account/BetListPayout";
import { JackpotBetList } from "../pages/Account/JackpotBetList";
import SportsFinancials from "../pages/Account/SportsFinancials";
import { useSelector } from "react-redux";
import { BonusTransactionList } from "../pages/Account/BonusTransactionList";
import Deposit from "../pages/Account/Deposit";
import BankDeposit from "../pages/Account/BankDeposit";
import BankWithdrawal from "../pages/Account/BankWithdrawal";
import { Expenses } from "../pages/Account/Expenses";
import { CashIn } from "../pages/Account/CashIn";
import { CashOut } from "../pages/Account/CashOut";
import PrintFixtures from "../pages/Sports/PrintFixtures";
import { CouponBetList } from "../pages/Account/CouponBetList";
import CashBook from "../pages/CashBook";
import TransferFunds from "../pages/Account/TransferFunds";
import { LoginHistory } from "../pages/Account/LoginHistory";
import NewUser from "../pages/Account/NewUser";

const AccountRoutes = ({ history }) => {
  const { isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!isAuthenticated) history.replace("/");
  }, [isAuthenticated]);

  return (
    <Switch>
      {/*<Redirect exact from="/Account" to="/Account/Overview" />*/}
      <Route
        exact
        path="/Account/TransactionList"
        component={TransactionList}
      />
      <Route
        exact
        path="/Account/BonusTransactionList"
        component={BonusTransactionList}
      />
      <Route exact path="/Account/Transfer/:type" component={TransferFunds} />
      <Route exact path="/Account/Expenses" component={Expenses} />
      <Route exact path="/Account/Cash-In" component={CashIn} />
      <Route exact path="/Account/Print-fixtures" component={PrintFixtures} />
      <Route exact path="/Account/Cash-Out" component={CashOut} />
      <Route exact path="/Account/Cash-Book" component={CashBook} />
      {/* <Route exact path="/Account/BetDetail/:betslip" component={BetDetail} /> */}
      <Route exact path="/Account/BetList" component={BetList} />
      <Route exact path="/Account/Deposit" component={Deposit} />
      <Route exact path="/Account/Withdrawal" component={BankWithdrawal} />
      <Route exact path="/Account/BankDeposit/:id" component={BankDeposit} />
      <Route exact path="/Account/BetListPayout" component={BetListPayout} />
      <Route exact path="/Account/JackpotBetList" component={JackpotBetList} />
      <Route
        exact
        path="/Account/FinancialReport"
        component={SportsFinancials}
      />
      <Route exact path="/Account/CouponBetList" component={CouponBetList} />
      <Route exact path="/Account/LoginHistory" component={LoginHistory} />
      <Route exact path="/Account/PersonalDetails" component={PersonalData} />
      <Route exact path="/Account/ChangePassword" component={ChangePassword} />
      <Route exact path="/Account/NewUser" component={NewUser} />
      <Route exact path="/Account/AgencyList" component={AgencyList} />
    </Switch>
  );
};

export default withRouter(AccountRoutes);
