import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useSWR from "swr";
import { AccountMenu } from "../Components/AccountMenu";
import { RightSide } from "../Components/RightSide";
import { SportsMenu } from "../Components/SportsMenu";
import { removeSelection } from "../Redux/actions";
import { SET_COUPON_DATA } from "../Redux/types";
import socket from "../Utils/socket";

export default function Sports({ children, history }) {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const { coupon } = useSelector((state) => state.couponData);
  const dispatch = useDispatch();
  const { data } = useSWR("/sports/live/outcomes");
  const liveOutcomes = data?.data;

  useEffect(() => {
    document.body.classList = "bodyMain en-GB sport_master oddsasync Logged";
  }, []);

  useEffect(() => {
    socket.on("change", (data) => {
      const couponData = { ...coupon };
      const selections = couponData.selections;
      for (let index = 0; index < selections.length; index++) {
        const selection = selections[index];

        if (selection.provider_id === parseInt(data.Match.matchid)) {
          if (data.Match.active !== "1") {
            // remove fixture from array
            dispatch(removeSelection(selection));
          } else {
            // update fixture odds
            if (Array.isArray(data.Match.Odds)) {
              for (const odds of data.Match.Odds) {
                if (
                  odds.freetext === selection.market_name &&
                  odds.id === selection.market_id
                ) {
                  //find market in existing markets
                  if (odds.OddsField) {
                    if (Array.isArray(odds.OddsField)) {
                      for (const odd of odds.OddsField) {
                        const outcome = liveOutcomes.find((outcome) => {
                          if (
                            outcome.market_name === odds.freetext &&
                            outcome.name === odd.type
                          ) {
                            if (
                              outcome.special_value &&
                              outcome.special_value === odds.specialoddsvalue
                            ) {
                              return outcome;
                            }
                            return outcome;
                          }
                        });

                        if (
                          odd.type === selection.odd_name &&
                          selection.odd_id === outcome.id
                        ) {
                          selection.odds = odd.$t;
                          selection.changed = true;
                        }
                      }
                    } else {
                      const odd = odds.OddsField;
                      const outcome = liveOutcomes.find((outcome) => {
                        if (
                          outcome.market_name === odds.freetext &&
                          outcome.name === odd.type
                        ) {
                          if (
                            outcome.special_value &&
                            outcome.special_value === odds.specialoddsvalue
                          ) {
                            return outcome;
                          }
                          return outcome;
                        }
                      });

                      if (
                        odd.type === selection.odd_name &&
                        selection.odd_id === outcome.id
                      ) {
                        selection.odds = odd.$t;
                        selection.changed = true;
                      }
                    }
                  }
                }
              }
            } else {
              const odds = data.Match.Odds;
              if (
                odds.freetext === selection.market_name &&
                odds.id === selection.market_id
              ) {
                //find market in existing markets
                if (odds.OddsField) {
                  if (Array.isArray(odds.OddsField)) {
                    for (const odd of odds.OddsField) {
                      const outcome = liveOutcomes.find((outcome) => {
                        if (
                          outcome.market_name === odds.freetext &&
                          outcome.name === odd.type
                        ) {
                          if (
                            outcome.special_value &&
                            outcome.special_value === odds.specialoddsvalue
                          ) {
                            return outcome;
                          }
                          return outcome;
                        }
                      });

                      if (
                        odd.type === selection.odd_name &&
                        selection.odd_id === outcome.id
                      ) {
                        selection.odds = odd.$t;
                        selection.changed = true;
                      }
                    }
                  } else {
                    const odd = odds.OddsField;
                    const outcome = liveOutcomes.find((outcome) => {
                      if (
                        outcome.market_name === odds.freetext &&
                        outcome.name === odd.type
                      ) {
                        if (
                          outcome.special_value &&
                          outcome.special_value === odds.specialoddsvalue
                        ) {
                          return outcome;
                        }
                        return outcome;
                      }
                    });

                    if (
                      odd.type === selection.odd_name &&
                      selection.odd_id === outcome.id
                    ) {
                      selection.odds = odd.$t;
                      selection.changed = true;
                    }
                  }
                }
              }
            }
          }
          selections[index] = selection;
          couponData.selections = selections;
          dispatch({ type: SET_COUPON_DATA, payload: couponData });
        }
      }
    });
  }, [socket]);

  return (
    <Fragment>
      <div className="spacer5"></div>
      <table cellPadding="0" cellSpacing="0" id="tblMainContent">
        <tbody>
          <tr>
            <td className="tdSX">
              <div className="SXCercaContent">
                <div className="TitleCerca">
                  <span>Search</span>
                </div>
                <div>
                  <input
                    name="s$w$PC$oddsSearch$txtSearch"
                    type="text"
                    maxlength="50"
                    className="TxtCerca"
                  />
                </div>
                <div>
                  <a title="Start search" className="BtnCerca" href="#"></a>
                </div>
              </div>
              <SportsMenu />

              {isAuthenticated && (
                <AccountMenu componentClase="loggedMenu" user={user} />
              )}
            </td>
            <td className="tdCN">{children}</td>
            <td className="tdDX">
              <RightSide />
            </td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  );
}
